<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData">

        <div class="row">

            <div class="col-12">
                <validated-text-area label="Text*" v-model="model.text"
                                     :rules="{required: true, max:150}" :disabled="loading"/>
            </div>

            <div class="col-12">
                <file-input class="c-file-input" ref="file" @input="clearFile" label="Banner Image*"
                            name="Banner Image" v-model="model.banner_image"
                            :disabled="loading">
                    <template #append>
                        <div class="btn-group">
                            <btn v-if="initialData.banner_image"
                                 @click="viewFile(initialData.banner_image)"
                                 type="button" class="fa fa-eye" size="xs"/>
                        </div>
                    </template>
                </file-input>
            </div>

            <div class="col-12">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>

            <div class="col-12 text-right">
                <btn class="trn" size="sm" :loading="loading" color="primary" text="Update"
                     loading-text="Updating..." :disabled="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'EditContactUs',

    props : { initialData : { type : Object } },

    data () {
        return {
            addUrl : urls.cms.contactUs.addEdit
        };
    },

    methods : {
        clearFile (file) {
            this.initialData.banner_image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            this.$notify('Successfully Edited Contact US..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
